<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
       <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>运营管理</el-breadcrumb-item>
      <el-breadcrumb-item>服务通知</el-breadcrumb-item>
      <el-breadcrumb-item>服务通知内容</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card style="overflow: auto">
      <el-row style="margin-bottom: 15px">
        <el-button @click="addialogVisible()" size="mini" type="primary">新增服务通知</el-button>
      </el-row>
      <!-- 分类搜索 -->
      <el-row>
        <el-col class="mr15" :span="3.3">
          <el-input size="mini" @input="keywordr()" placeholder="标题" v-model="queryInfo.keyword">
            <el-button slot="append" icon="el-icon-search"></el-button>
          </el-input>
        </el-col>
        <!-- <el-col class="mr15" :span="3.3">
          <el-input size="mini" placeholder="类型"   >
            <el-button slot="append" icon="el-icon-search"></el-button>
          </el-input>
        </el-col> -->
      </el-row>
      <!--商品数据展示区域-->
      <el-table
        v-loading="loading"
        :data="tableData"
        style="width: 1100px"
        ref="singleTable"
        highlight-current-row
        :header-cell-style="{'text-align':'center'}"    
       :cell-style="{'text-align':'center'}"
      
        row-key="id"
        stripe
      >
      <el-table-column type="selection" width="50"/>
        <el-table-column prop="id" label="序号" width="80">
        
        </el-table-column>
        <el-table-column  label="业务" width="150" show-overflow-tooltip>
        <template slot-scope="scope">
          <span v-if="scope.row.businessType==1">电商</span>
        <span v-else-if="scope.row.businessType==2">代驾</span>
        <span v-else-if="scope.row.businessType==3">其他</span>
    
        </template>
        </el-table-column>
        <el-table-column prop="title" label="标题" width="250" show-overflow-tooltip/>
       
         
        <el-table-column  label="通知对象" width="150" show-overflow-tooltip>
        <template slot-scope="scope">
        <span v-if="scope.row.userType==1&&scope.row.businessType==1">买家</span>
        <span v-else-if="scope.row.userType==1&&scope.row.businessType==2">乘客</span>
        <span v-if="scope.row.userType==2">卖家</span>
        <span v-else-if="scope.row.userType==3">代驾司机</span>
        <span v-else-if="scope.row.userType==4">其他</span>
        </template>
         </el-table-column>
        <!-- <el-table-column  label="用户类型" width="150" show-overflow-tooltip>
        <template slot-scope="scope">
        <span v-if="scope.row.businessType==1">电商</span>
        <span v-else-if="scope.row.businessType==2">代驾</span>
        <span v-else-if="scope.row.businessType==3">其他</span>
        </template>
         </el-table-column> -->
         <!-- <el-table-column  label="内容" width="250" show-overflow-tooltip>
        <template slot-scope="scope">
        <span v-if="scope.row.contentUrl">{{scope.row.contentUrl}}</span>
      <span v-else>{{scope.row.content}}</span>
        </template>
         </el-table-column> -->
        <el-table-column  prop="createTime" label="时间" width="250" show-overflow-tooltip>
       
        </el-table-column>
        <el-table-column label="操作" min-width="100" fixed="right">
         <template  slot-scope="scope">
          <el-button @click="dialogVisibliecion(scope.row.id)" size="mini" type="text" >编辑</el-button>
         <el-popconfirm @confirm="deleter(scope.row.id)" style="padding-left: 20px;" title="确定删除此内容吗？">
          <el-button  slot="reference" size="mini" type="text">删除</el-button>
          </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <!--分页-->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.page"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="queryInfo.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <!-- 添加服务通知 -->
    <el-dialog
      width="60%"
      title="新增服务通知内容"
      :append-to-body="true"
      :visible.sync="dialogVisible"
    >
  <el-form :model="ServiceDate">
      <el-form-item label="业务" required>
       <el-radio-group v-model="ServiceDate.businessType">
    <el-radio :label="1">电商</el-radio>
    <el-radio :label="2">代驾</el-radio>
  </el-radio-group>
        </el-form-item>
        <div style="display: flex; justify-content: left">
          <el-form-item label="标题" style="width: 270px" required>
            <el-col :span="3.3">
              <el-input size="mini" v-model="ServiceDate.title"></el-input>
            </el-col>
          </el-form-item>
          <el-form-item style="width: 300px" label="类型" required>
            <el-select
              size="mini"
              v-model="ServiceDate.typeId"
              clearable
              placeholder="类型分类"
             @change="onServiceDate()"
              class="mr15"
            >
              <el-option
                v-for="(item, index) in merchantStatusList"
                :key="index"
                :label="item.typeName"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </div>
          <div style="display: flex; justify-content: left">
            <el-form-item v-if="ServiceDate.businessType===1" style="width: 600px" label="通知对象" required>
              <el-checkbox v-model="checked1">买家</el-checkbox>
              <el-checkbox v-model="checked2">卖家</el-checkbox>
            </el-form-item>
             <el-form-item v-else style="width: 600px" label="通知对象" required>
              <el-checkbox v-model="checked3">乘客</el-checkbox>
              <el-checkbox v-model="checked4">代驾司机</el-checkbox>
            </el-form-item>
            <el-form-item style="width: 600px" label="内容" required>
              <el-radio v-model="radio" label="1">自定义</el-radio>
              <el-radio v-model="radio" label="2">链接</el-radio>
            </el-form-item>
           </div>
        <!-- 富文本子组件 -->
        <div v-show="radio == 1">
    <editor v-if="dialogVisible" :value="content" v-model="content" :uploadUrl="'http://api.fuhangbeiyue.com/api/platform/uploadImages'" />
          </div>
           
        <!-- 选择链接 -->
        <div v-show="radio == 2">
          <el-form-item label="链接" required>
            <el-input style="width: 60%" size="mini" v-model="ServiceDate.contentUrl"></el-input>
          </el-form-item>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="danger" @click="addServiceflase()">取消</el-button>
        <el-button type="primary" @click="addService()">确定</el-button>
      </span>
    </el-dialog>

     <!-- 编辑服务通知 -->
    <el-dialog
      width="60%"
      title="编辑服务通知内容"
      :append-to-body="true"
      :visible.sync="dialogVisibles"
    >
  <el-form :model="tablecomopeData">
      <el-form-item label="业务" required>
       <el-radio-group v-model=" tablecomopeData.businessType">
    <el-radio :label="1" disabled>电商</el-radio>
    <el-radio :label="2" disabled>代驾</el-radio>
  
  </el-radio-group>
        </el-form-item>
        <div style="display: flex; justify-content: left">
          <el-form-item label="标题" style="width: 270px" required>
            <el-col :span="3.3">
              <el-input size="mini" v-model="tablecomopeData.title"></el-input>
            </el-col>
          </el-form-item>
          <el-form-item style="width: 300px" label="类型" required>
            <el-select
              size="mini"
              v-model="tablecomopeData.typeId"
              clearable
              @change="onServiceDates()"
              placeholder="类型分类"
              class="mr15"
            >
              <el-option
                v-for="(item, index) in merchantStatusList"
                :key="index"
                :label="item.typeName"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </div>
        
          <div style="display: flex; justify-content: left">
            <el-form-item v-if=" tablecomopeData.businessType===1" style="width: 600px" label="通知对象："   required>
            <span v-if="checked1">买家</span>
            <span v-else>卖家</span>
            </el-form-item>
             <el-form-item v-else style="width: 600px" label="通知对象" required>
              <span v-if="checked3">乘客</span>
              <span v-else>代驾司机</span>
            </el-form-item>
            <el-form-item style="width: 600px" label="内容" required>
             <el-radio-group v-model=" rad">
              <el-radio  :label="1">自定义</el-radio>
              <el-radio :label="2">链接</el-radio>
              </el-radio-group>
            </el-form-item>
           </div>
        <!-- 选择自定义 -->
        <div v-show="rad == 1">
        <!-- 富文本子子组件 -->
     <editor v-if="dialogVisibles" :value="content" v-model="contentr" :uploadUrl="'http://api.fuhangbeiyue.com/api/platform/uploadImages'" />
        </div>
        <!-- 选择链接 -->
        <div v-show="rad == 2">
          <el-form-item label="链接" required>
            <el-input style="width: 60%" size="mini" v-model=" tablecomopeData.contentUrl"></el-input>
          </el-form-item>
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibles=false" type="danger" >取消</el-button>
        <el-button @click="compiletype()" type="primary" >确定</el-button>
      </span>
    </el-dialog>

  </div>
</template>
<script>
import Editor from "@/components/Editor";
import time from '../../utils/time'
import {updateSystemMessage ,searchSystemMessageById, 
        searchSystemMessageList,insertSystemMessage,searchSystemMessageTypeList,
        delSystemMessage} from "@/api";
export default {
  components:{
 Editor
  },
  data() {
    
    return {
      content:"",
      contentr:"",
      form:{},
      // 编辑服务通知内容信息
       tablecomopeData: {
         
       },
       dialogVisibles:false,
      checked1:false,
      checked2:false,
      checked3:false,
       checked4:false,
      // 分类列表
      merchantStatusList:[],
      // 添加服务通知内容
      ServiceDate:{
        businessType:1,
        contentUrl:"",
        content:"",
       userTypes:[]
      },
      rad:0,
      radio: 0,
      dialogVisiblie: false,
      dialogVisible: false,
      tableData: [
     
      ],
       loading: false,
      // 查找商品列表参数对象
      queryInfo: {
        keyword:"",
       page:1,
       size:10
      },
      total: 0,
    };
  },
created(){
  // 获取列表消息
this.getGoodsList()
},
  methods: {
    onServiceDates(){
this.merchantStatusList.forEach(element => {
  if(element.id==this.tablecomopeData.typeId){
    this.tablecomopeData.logo=element.logo
     this.tablecomopeData.typeName=element.typeName
     
  }
});
    },
    onServiceDate(){
       
this.merchantStatusList.forEach(element => {
  if(element.id==this.ServiceDate.typeId){
    this.ServiceDate.logo=element.logo
     this.ServiceDate.typeName=element.typeName
  }
});
    },
    // 确认编辑
   async   compiletype(){

  if (!this.tablecomopeData.title) return this.$message.error("请输入标题")
    if (!this.tablecomopeData.typeId) return this.$message.error("请输现在类型")
    this.tablecomopeData.userTypes=[]

      if(this.tablecomopeData.businessType==1){
      if(this.checked1||this.checked2){
     if(this.checked1){
       this.tablecomopeData.userTypes.push(1)
     }
      if(this.checked2){
       this.tablecomopeData.userTypes.push(2)
     }
         }else{
              return this.$message.error("请选择通知对象")
       }
      }else{
        console.log(this.checked4);
    if(this.checked3||this.checked4){
       if(this.checked3){
       this.tablecomopeData.userTypes.push(1)
     }
      if(this.checked4){
       this.tablecomopeData.userTypes.push(3)
     }
     }else{
   return this.$message.error("请选择通知对象")
    }
  }
     if (this.rad==2){
       if(!this.tablecomopeData.contentUrl)return this.$message.error("请输入链接")
       this.form.content= ""
    this.contentr= ""
     }else{
      
       if(!this.tablecomopeData.content)return this.$message.error("请输入正文")
       this.tablecomopeData.contentUrl=""
        let oldProcedure =  ` <html lang="en"><head><meta charset="utf-8"><title>${this.tablecomopeData.title}</title><style>body{font-size: 14px}.ql-syntax{background-color: #23241f;color: #f8f8f2;}.ql-font-monospace{font-family: Monaco, Courier New, monospace;  }.ql-align-center { text-align: center;}.ql-align-right {text-align: right;}.ql-align-justify {text-align: justify;  text-indent: 2em;}.ql-font-serif { font-family: Georgia, Times New Roman, serif; overflow: visible;} .ql-video{width: 100%;} .ql-size-small{font-size: 10px}.ql-size-large{font-size: 18px}.ql-size-huge{font-size: 32px}.ql-align-center{text-align: center}.ql-align-justify{text-align: justify}.ql-align-right{text-align: right}</style></head><body>${this.contentr}</body></html> `
         this.tablecomopeData.content= oldProcedure;
     }
      const { data: res } = await updateSystemMessage(this.tablecomopeData)  
      if (res.code !== 200) return this.$message.error("修改失败请稍后重试");
       this.dialogVisibles=false
               this.loading=false
                this.$message.success('修改成功')
     this.getGoodsList()
    },
    //根基id查询详情
   async  dialogVisibliecion(id){
     const { data: res } = await searchSystemMessageById({id:id})
  if (res.code !== 200) return this.$message.error("信息获取错误请稍后重试");
 this.tablecomopeData=res.body
this.tablecomopeData.userTypes=[]
if(!this.tablecomopeData.content==""){
  this.rad=1
 this.contentr= this.tablecomopeData.content
}else{
  this.form.content=""
  this.rad=2
}
  this.dialogVisi()
 if( this.tablecomopeData.businessType==1){
   if(this.tablecomopeData.userType==1){
     this.checked1=true
   }else{
      this.checked2=true
   }
 }else{
   if(this.tablecomopeData.userType==1){
     this.checked3=true
   }else{
      this.checked4=true
   }
 }

 this.getservicetype()
 this.dialogVisibles=true
    },
    // 删除服务内容
async deleter(id){
  const formData = new FormData()
  formData.append("ids", id);
  const { data: res } = await delSystemMessage(formData)
  if (res.code !== 200) return this.$message.error("删除失败请稍后重试");
  this.$message.success("删除成功");
     this.loading=false
  this.getGoodsList()
},

    addServiceflase(){
      this.ServiceDate={ businessType:1,contentUrl:"", content:"", userTypes:[]}
 this.dialogVisible = false
    },
//  添加内容信息
  async addService(){
  if (!this.ServiceDate.title) return this.$message.error("请输入标题")
    if (!this.ServiceDate.typeId) return this.$message.error("请输现在类型")
     this.ServiceDate.userTypes=[]
      if(this.ServiceDate.businessType==1){
      if(this.checked1||this.checked2){
     if(this.checked1){
       this.ServiceDate.userTypes.push(1)
     }
      if(this.checked2){
       this.ServiceDate.userTypes.push(2)
     }
         }else{
              return this.$message.error("请选择通知对象")
       }
      }else{
    if(this.checked3||this.checked4){
       if(this.checked3){
       this.ServiceDate.userTypes.push(1)
     }
      if(this.checked4){
       this.ServiceDate.userTypes.push(3)
     }
     }else{
   return this.$message.error("请选择通知对象")
    }
  }
     if (this.radio==2){
       if(!this.ServiceDate.contentUrl)return this.$message.error("请输入链接")
     }else{
       if(!this.content)return this.$message.error("请输入正文")
       this.ServiceDate.contentUrl=""
        let oldProcedure =  ` <html lang="en"><head><meta charset="utf-8"><title>${this.ServiceDate.title}</title><style>body{font-size: 14px}.ql-syntax{background-color: #23241f;color: #f8f8f2;}.ql-font-monospace{font-family: Monaco, Courier New, monospace;  }.ql-align-center { text-align: center;}.ql-align-right {text-align: right;}.ql-align-justify {text-align: justify;  text-indent: 2em;}.ql-font-serif { font-family: Georgia, Times New Roman, serif; overflow: visible;} .ql-video{width: 100%;} .ql-size-small{font-size: 10px}.ql-size-large{font-size: 18px}.ql-size-huge{font-size: 32px}.ql-align-center{text-align: center}.ql-align-justify{text-align: justify}.ql-align-right{text-align: right}</style></head><body><iframe src="https://shopic.sf-express.com" frameborder="0"></iframe></body></html> `
         this.ServiceDate.content= oldProcedure;
     }
   
      const { data: res } = await insertSystemMessage( this.ServiceDate);
      if (res.code !== 200) return this.$message.error("服务通知类型添加错误请稍后重试")
     this.ServiceDate={ businessType:1,contentUrl:"", content:"", userTypes:[]}
     this.content=""
      this.dialogVisible = false
               this.loading=false
               this.$message.success('添加成功')
               
     this.getGoodsList()
},
// 点击新增按钮
addialogVisible(){
  this.getservicetype()
  this.form.content=""
this.dialogVisible = true
},

     // 获取分类
   async  getservicetype(){
      const { data: res } = await searchSystemMessageTypeList( );
      if (res.code !== 200) return this.$message.error("服务通知类型获取错误请稍后重试")
      this.merchantStatusList=res.body.list
      

    },
    // 模糊搜索
    keywordr(){
  this.queryInfo.page=1
     this.loading=false
  this.getGoodsList() 
    },
    // 下一页
    handleCurrentChange(data) {
this.queryInfo.page=data
   this.loading=false
this.getGoodsList() 
    },
    // 切换每页数量
handleSizeChange(date){
  this.queryInfo.page=1
  this.queryInfo.size=date
     this.loading=false
this.getGoodsList() 
},
// 多选初始化
  dialogVisi(){
       this.checked1=false
       this.checked2=false
       this.checked3=false
       this.checked4=false
    },

       /*
     *
     * 默认请求第一页 十条数据
     * */
  async getGoodsList() {
        const { data: res } = await searchSystemMessageList( this.queryInfo);
        if (res.code !== 200) return this.$message.error("服务通知列表错误")
        this.tableData = res.body.list;
         for (let index = 0; index < this.tableData.length; index++) {
 this.tableData[index].createTime= time(this.tableData[index].createTime)
}
        this.total = res.body.total;
        this.loading=false
    },
   
    async newGetAllGoodCategoryr() {
      const { data: res } = await newGetAllGoodCategory();
      this.oneCategoryList = res.result;
    },
    // 退出
    logout() {
      window.sessionStorage.clear();
      this.$router.push("/login");
    },
    // 返回首页
    backHome() {
      this.$router.push("/index");
    
    },
  },
  watch:{
  
    radio(){
  this. ServiceDate.contentUrl=""
    this. ServiceDate.content=""
    }
  }
};

</script>

<style lang="scss">
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 150px;
  height: 150px;
  line-height: 150px;
  text-align: center;
}
.avatar {
  width: 150px;
  height: 150px;
  display: block;
}
.el-information {
  .el-col {
    margin-bottom: 15px;
  }
}
.abow_dialog {
  font-weight: bold;

  p {
    float: left;
  }
}

.el-icon-s-fold,
.el-icon-s-unfold {
  font-size: 35px;
  margin: 22px;
  color: #999;
}
.mr15 {
  margin-right: 15px;
}

</style>